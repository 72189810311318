<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <orders-filter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
        <eden-periods
          class="ml-10"
          :allow-all="true"
          :loading="loading"
          :default-period="'today'"
          @set-period="getOrders($event)"
        />
      </template>
    </eden-table-actions>
    <template>
      <eden-loader v-if="loading" />
      <template v-else>
        <eden-filter-items
          v-if="filterParams.status"
          :params="filterParams.paramsLabel"
          @clear-filter="clearFilter"
          @clear-filters="clearFilters"
        />
        <el-table :data="pageData">
          <el-table-column width="100">
            <template slot="header">
              <div class="table--header">
                <span>Order Id</span>
              </div>
            </template>
            <template slot-scope="scope">
              <router-link
                class="text-primary"
                :to="{
                  name: 'orders.order',
                  params: { id: scope.row.order_id },
                }"
              >
                {{ scope.row.order_id }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Name</span>
              </div>
            </template>
            <template slot-scope="scope">
              <p>
                <router-link
                  :to="{
                    name: 'customers.individual',
                    params: { id: scope.row.user_id },
                  }"
                >
                  {{ formatName(scope.row.customer_name) }}
                </router-link>
              </p>
              <span class="font-xsm text-grey-tertiary">
                {{ scope.row.customer_email }}</span
              >
            </template>
          </el-table-column>
          <el-table-column width="180">
            <template slot="header">
              <div class="table--header">
                <span>Gardener</span>
              </div>
            </template>
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.gardener_for_customer"
                class="text-grey-primary"
                :to="{
                  name: 'gardeners.gardener',
                  params: { id: scope.row.gardener_id },
                }"
                >{{ scope.row.gardener_for_customer }}</router-link
              >
              <p v-else>-</p>
            </template>
          </el-table-column>
          <el-table-column width="160">
            <template slot="header">
              <div class="table--header">
                <span>Service</span>
              </div>
            </template>
            <template slot-scope="scope">
              <el-tag :type="setServiceType(scope.row.service)"
                >{{ scope.row.service }}
                {{ scope.row.is_one_time_order ? "One-time" : "" }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column width="160">
            <template slot="header">
              <div class="table--header">
                <span>Order Type</span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              {{ formatText(scope.row.order_type) }}
            </template>
          </el-table-column>
          <el-table-column width="180">
            <template slot="header">
              <div class="table--header">
                <span>Customer Pickup</span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <span
                class="font-sm"
                v-if="scope.row.service.toLowerCase() === 'laundry'"
              >
                {{ formatDate(scope.row.pickup_from_customer, "do m, y") }}
              </span>
              <span class="font-sm" v-else>Delivery only</span>
            </template>
          </el-table-column>
          <el-table-column width="160">
            <template slot="header">
              <div class="table--header">
                <span>Ordered At</span>
              </div>
            </template>
            <template slot-scope="scope">
              <el-tag type="success" class="text-normalize">
                {{ formatDateAndTime(scope.row.created_at, "do m,y") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column width="180">
            <template slot="header">
              <div class="table--header">
                <span>Delivery</span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatDate(scope.row.deliver_to_customer, "do m, y") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template slot="header">
              <div class="table--header">
                <span>Status</span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm table--status">
                <eden-order-status
                  :service="scope.row.service.toLowerCase()"
                  :activity="scope.row.last_marked_activity"
                  :completed-status="scope.row.completed_status"
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination && pageData.length"
          :from="from"
          :to="to"
          :total="total"
          :current-page.sync="page"
        />
      </template>
    </template>
  </div>
</template>

<script>
import OrdersFilter from "@/components/Orders/OrdersFilter";

import orders from "@/requests/orders";
import * as actions from "@/store/action-types";

export default {
  name: "OrdersSchedule",
  components: { OrdersFilter },
  data() {
    return {
      loading: false,
      period: "today",
      page: 1,
      pageData: [],
      showPagination: true,
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
    };
  },
  computed: {
    orders() {
      return this.$store.getters.orders;
    },
    from() {
      return this.orders.pages[this.page].from;
    },
    to() {
      return this.orders.pages[this.page].to;
    },
    total() {
      return this.orders.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;

      return `${this.showPagination ? total || 0 : result} Orders`;
    },
  },
  watch: {
    searchQuery() {
      if (this.searchQuery !== "") {
        this.search();
      } else {
        this.setPageData();
      }
    },
    page() {
      const pageFetched = !!this.orders.pages[this.page];
      if (!pageFetched) {
        this.getOrders({ period: this.period });
      } else {
        this.setPageData();
      }
    },
  },
  created() {
    this.getOrders({ period: this.period });
  },
  methods: {
    getOrders({ period }) {
      console.log(period, "_____________");
      this.loading = true;
      this.$store
        .dispatch(actions.GET_ORDERS, {
          period,
          page: this.page,
        })
        .then(() => {
          this.setPageData();
          this.period = period;
          this.loading = false;
        });
    },
    search(query) {
      this.loading = true;
      orders
        .search(query, this.period)
        .then((response) => {
          this.pageData = response.data.data;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.setPageData();
        });
    },
    filter({ params, paramsLabel }) {
      this.loading = true;

      const payload = {
        period: params.period ?? this.period,
        status: params.status ?? "all",
        gardener_id: params.gardener_id ?? "all",
        service: params.service ?? "all",
        order_type: params.order_type ?? "all",
      };

      orders
        .filter(payload)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          this.pageData = response.data?.data?.data;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.orders.pages[this.page].data;
      this.showPagination = true;
      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
    setPeriod(period) {
      this.period = period;
    },
    setSearchQuery(query) {
      this.searchQuery = query;
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.setPageData();
      }
    },
    clearFilters() {
      this.$router.push({ name: "orders.index" });
      this.setPageData();
    },
  },
};
</script>
